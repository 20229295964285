<template>
    <div>
        <v-dialog v-model="showLieferstellen" width="800">
            <v-card class="mx-auto" outlined>
                <v-card-title>Lieferstellen</v-card-title>
                <v-card-text>
                    <v-simple-table fixed-header dense>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">#</th>
                                    <th class="text-left">Marktlokation</th>
                                    <th class="text-left">Straße und Haus-Nr.</th>
                                    <th class="text-left">PLZ</th>
                                    <th class="text-left">Ort</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(marktlokation, index) in aktuellerVertrag['marktlokation']" :key="marktlokation.nummer">
                                    <td> {{ index + 1 }}</td>
                                    <td>{{ marktlokation.nummer }}</td>
                                    <td>
                                        {{ marktlokation.strasse }} {{ marktlokation.hausnummer }}{{
                                            marktlokation.hausnummer_zusatz }}
                                    </td>
                                    <td>
                                        {{ (marktlokation.plz+ "").padStart(5, "0") }}
                                    </td>
                                    <td> {{ marktlokation.ort }}
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green-darken-1" variant="text" @click="showLieferstellen = false">
                        Schließen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <bestellungAusfuehren :active="dialog.bestellungAusfuehren" :orderdata="this.orderdata">
        </bestellungAusfuehren>
        <v-row>
            <v-col cols="12" md="6">
                <v-card class="mx-auto" outlined>
                    <v-list-item three-line>
                        <v-list-item-content>
                            <div class="text-overline mb-4">{{ getCurrentFirma.id }}</div>
                            <v-list-item-title class="text-h5 mb-1">
                                <v-select v-if="isFirmenArray" item-text="name" :items="getFirmenList"
                                    :value="getCurrentFirma" v-on:change="setCurrentFirma" return-object></v-select>
                                <div v-else>{{ getCurrentFirma.name }}</div>
                            </v-list-item-title>
                            <v-list-item-subtitle>
                                {{ getCurrentFirma.strasse }} {{ getCurrentFirma.hausnummer
                                }}{{ getCurrentFirma.hausnummer_zusatz }}<br />
                                {{ (getCurrentFirma.plz+ "").padStart(5, "0") }} {{ getCurrentFirma.ort }}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-card>
            </v-col>
            <v-col cols="12" md="6">
                <v-card class="mx-auto" outlined>
                    <v-simple-table dense>
                        <template v-slot:default>
                            <tbody>
                                <tr>
                                    <td>Vertragsnummer </td>
                                    <td>
                                        <v-select v-model="localAktuellerVertragsname"
                                            :items="aktuelleVertragsnamen" item-text="Vertrag"
                                            single-line></v-select>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Vertragsart</td>
                                    <td>
                                        {{ aktuellerVertrag.vertragsart }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Energieart</td>
                                    <td>
                                        {{ aktuellerVertrag.commodity }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Vertragsbeginn</td>
                                    <td>
                                        {{ aktuellerVertrag.vertragsbeginn }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Vertragsende</td>
                                    <td>
                                        {{ aktuellerVertrag.vertragsende }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Anzahl Lieferstellen</td>
                                    <td>
                                        {{ aktuellerVertrag.anzahlMarktlokationen }}
                                        (<v-btn plain small @click="showLieferstellen = !showLieferstellen">
                                            <span v-if="showLieferstellen == false">Anzeigen</span>
                                            <span v-if="showLieferstellen == true">Verbergen</span>
                                        </v-btn>)

                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col v-if="aktuelleTranche.Produkte && !abgeschlossenerVertragsart" cols="12" md="6">
                <v-card class="mx-auto" outlined :key="componentKey">
                    <v-card-title>Aktuelle Notierungen</v-card-title>
                    <v-simple-table fixed-header dense>
                        <template v-slot:default>
                            <tbody>
                                <tr>
                                    <td>Base<sub>NEW</sub>({{ aktuelleTranche.Produkte.basetext }})</td>
                                    <td>{{
                                        parseFloat(aktuelleTranche.Preise.base.price).toLocaleString("de-DE",
                                            {
                                                maximumFractionDigits: 2,
                                            }) }} €/MWh</td>
                                </tr>
                                <tr v-if="aktuelleTranche.Produkte.peak">
                                    <td>Peak<sub>NEW</sub> ({{ aktuelleTranche.Produkte.peaktext }})
                                    </td>
                                    <td>{{
                                        parseFloat(aktuelleTranche.Preise.peak.price).toLocaleString("de-DE",
                                            {
                                                maximumFractionDigits: 2,
                                            }) }} €/MWh</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card>
            </v-col>
            <v-col cols="12" md="6">
                <v-card class="mx-auto" outlined :key="stopplossKey" v-if="readonly == true">
                    <v-card-title>Aktuelle Limits (Base<sub>NEW</sub>)</v-card-title>
                    <v-simple-table fixed-header dense>
                        <template v-slot:default>
                            <tbody>
                                <template v-if="aktuellerStoppLoss.filter((sl) => sl.status != 'geschlossen').length <= 0">
                                    <tr>
                                        <div style="margin: 5px;">
                                            Die Bewirtschaftung für das Lieferjahr {{ aktuelleTranche.Laufzeitbis && aktuelleTranche.Laufzeitbis.split(".")[2] }} ist vollständige abgeschlossen.
                                        </div>
                                    </tr>
                                </template>
                                <template v-else>
                                    <tr v-for="sl of aktuellerStoppLoss" :key="sl.name">
                                        <td>{{ sl.name }}</td>
                                        <td v-if="sl.status != 'geschlossen'">{{ sl.limit.toLocaleString("de-DE", { maximumFractionDigits: 2 }) }} €/MWh</td>
                                        <td v-else>Die Bewirtschaftung für den angegeben Zeitbereich ist abgeschlossen.</td>
                                    </tr>
                                </template>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="6">
                <v-card class="mx-auto" outlined :key="componentKey">
                    <v-card-title>Tranchen: </v-card-title>
                    <v-row>
                        <v-col>
                            <v-simple-table dense>
                                <template v-slot:default>
                                    <tbody>
                                        <tr v-if="aktuelleTranchenzeitraeume.length > 1">
                                            <td>Zeitraum</td>
                                            <td>
                                                <v-select v-model="aktuellerTranchenzeitraum
                                                    " :items="aktuelleTranchenzeitraeume
        " item-text="Vertrag" single-line></v-select>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Laufzeit von</td>
                                            <td>
                                                {{ aktuelleTranche.Laufzeitvon }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Laufzeit bis</td>
                                            <td>
                                                {{ aktuelleTranche.Laufzeitbis }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Einzeltranche in kWh</td>
                                            <td>
                                                {{
                                                    Math.round(germanNumberStringToNumber(
                                                        aktuelleTranche
                                                            .TrancheMengeinkWh
                                                    )).toLocaleString("de-DE")
                                                }}
                                            </td>
                                        </tr>
                                        <tr v-if="aktuelleTranche.Preise.base.price&&aktuelleTranche.Preise.peak.price">
                                            <td>
                                                aktueller Preis Einzeltranche pro MWh ({{ priceDate }})
                                            </td>
                                            <td v-if="aktuellerVertrag.commodity ===
                                                'STROM'
                                                ">
                                                {{ aktuelleTranche.FaktorBase }} x
                                                {{
                                                    parseFloat(
                                                        aktuelleTranche.Preise.base
                                                            .price
                                                    ).toLocaleString("de-DE", {
                                                        maximumFractionDigits: 2,
                                                    })
                                                }}
                                                €/MWh +
                                                {{ aktuelleTranche.FaktorPeak }} x
                                                {{
                                                    parseFloat(
                                                        aktuelleTranche.Preise.peak
                                                            .price
                                                    ).toLocaleString("de-DE", {
                                                        maximumFractionDigits: 2,
                                                    })
                                                }}
                                                €/MWh +
                                                {{
                                                    aktuelleTranche.Strukturkonstante.toLocaleString(
                                                        "de-DE",
                                                        { maximumFractionDigits: 2 }
                                                    )
                                                }}
                                                €/MWh =
                                                {{
                                                    aktuelleTranche.Endpreis.price.toLocaleString(
                                                        "de-DE",
                                                        { maximumFractionDigits: 2 }
                                                    )
                                                }}
                                                €/MWh
                                            </td>
                                            <td v-else>
                                                {{ aktuelleTranche.FaktorBase }} x
                                                {{
                                                    parseFloat(
                                                        aktuelleTranche.Preise.base
                                                            .price
                                                    ).toLocaleString("de-DE", {
                                                        maximumFractionDigits: 2,
                                                    })
                                                }}
                                                €/MWh +

                                                {{
                                                    aktuelleTranche[
                                                        "Strukturkonstante"
                                                    ].toLocaleString("de-DE", {
                                                        maximumFractionDigits: 2,
                                                    })
                                                }}
                                                € =
                                                {{
                                                    aktuelleTranche.Endpreis.price.toLocaleString(
                                                        "de-DE",
                                                        { maximumFractionDigits: 2 }
                                                    )
                                                }}
                                                €/MWh
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                            <v-simple-table fixed-header dense>
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="text-left grey lighten-2">Tranche</th>
                                            <th class="text-left grey lighten-2">Energievolumen (kWh)<br><span class="font-italic">gerundet auf kWh</span></th>
                                            <th class="text-left grey lighten-2">Kosten der Tranche (€)</th>
                                            <th class="text-left grey lighten-2">Preis (€/MWh)</th>
                                            <th class="text-left grey lighten-2">Bestellstatus</th>
                                        </tr>

                                    </thead>
                                    <tbody>
                                        <tr v-for="tranchennummer in sortedTranchen" :key="tranchennummer">


                                            <td>
                                                {{ tranchennummer }}
                                            </td>
                                            <td>
                                                {{
                                                    Math.round(germanNumberStringToNumber(
                                                        aktuelleTranche
                                                            .TrancheMengeinkWh
                                                    )).toLocaleString("de-DE")
                                                }}
                                            </td>
                                            <td>
                                                <span v-if="!geschlosseneTranchenNamen.includes(
                                                            tranchennummer
                                                        )
                                                        ">
                                                    {{
                                                        aktuelleTranche[
                                                            "cost"
                                                        ].toLocaleString("de-DE", {
                                                            maximumFractionDigits: 2,
                                                        })
                                                    }}
                                                </span>
                                                <span v-else>
                                                    {{
                                                        geschlosseneTranchen[
                                                            tranchennummer
                                                        ].cost.toLocaleString("de-DE", {
                                                            maximumFractionDigits: 2,
                                                        })
                                                    }}</span>
                                            </td>
                                            <td>
                                                <span v-if="!geschlosseneTranchenNamen.includes(
                                                            tranchennummer
                                                        )
                                                        ">
                                                    {{
                                                        aktuelleTranche["Endpreis"][
                                                            "price"
                                                        ].toLocaleString("de-DE", {
                                                            maximumFractionDigits: 2,
                                                        })
                                                    }}</span>
                                                <span v-else>
                                                    {{
                                                        geschlosseneTranchen[
                                                            tranchennummer
                                                        ].pricing.price.toLocaleString("de-DE", {
                                                            maximumFractionDigits: 2,
                                                        })
                                                    }}</span>
                                            </td>
                                            <td>
                                                <span v-if="!geschlosseneTranchenNamen.includes(
                                                            tranchennummer
                                                        )
                                                        ">
                                                    <a v-if="naechsterOffenerTranchenName ===
                                                        tranchennummer && !readonly && parseInt(
                                                            aktuelleTranche.TrancheMengeinkWh
                                                        ) > 0
                                                        " v-on:click="setOrder(tranchennummer)">Jetzt bestellen</a>
                                                </span>
                                                <span v-else>
                                                    Bestellt am
                                                    {{
                                                        getDateFromTS(
                                                            geschlosseneTranchen[
                                                                tranchennummer
                                                            ].orderdatetime
                                                        )
                                                    }}</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th class="text-left grey lighten-4">Summe</th>
                                            <th class="text-left grey lighten-4 ">{{
                                                TrachenSummen.volume }} / {{
        TrachenSummen.volumeAllTranches }} kWh</th>
                                            <th class="text-left grey lighten-4">{{ TrachenSummen.cost
                                            }}€ Kosten bisher
                                            </th>
                                            <th class="text-left grey lighten-4">{{
                                                TrachenSummen.price }} mittlerer Preis
                                                (€/MWh)</th>
                                            <th class="text-left grey lighten-4">{{
                                                TrachenSummen.numberClosed }} / {{
        TrachenSummen.numberAllTranches }} Tranchen
                                                geschlossen</th>
                                        </tr>
                                    </tfoot>
                                </template>
                            </v-simple-table>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
            <v-col cols="12" md="6">

            </v-col>
        </v-row>
        <v-row v-if="isLoading" class="text-center">
            <v-col>
                <loading :active="isLoading" :color=loading.color :can-cancel=loading.canCancel
                    :is-full-page=loading.isFullPage />
            </v-col>
        </v-row>
        <v-row v-else>
            <v-col><v-card class="mx-auto" outlined v-if="chartData.datasets != []">
                    <v-card-title>Marktpreisentwicklung</v-card-title>
                    <evchart :chartOptions="computedChartdata.chartOptions" :chartData="computedChartdata.chartData"></evchart>
                </v-card>
            </v-col>
            <v-col><v-card class="mx-auto" outlined>
                    <v-card-title>StoppLoss Limits</v-card-title>
                    <evchart :chartOptions="computedChartdata.detailChartOptions" :chartData="computedChartdata.detailChartData"></evchart>
                </v-card>
            </v-col>
        </v-row>
        <v-divider></v-divider>
    </div>
</template>

<script>
import loading from "vue-loading-overlay"
import bestellungAusfuehren from "@/components/dialog/bestellungAusfuehren"
import axios from "axios"
import { format, parse } from "date-fns";
import evchart from "@/components/evchart.vue"
import { mapState } from "vuex";

export default {
    components: {
        bestellungAusfuehren,
        evchart,
        loading
    },
    data() {
        return {
            isLoading: false,
            loading: {
                isFullPage: true,
                canCancel: false,
                color: "#78AEF5"
            },
            readonly: true,
            priceDate: "",
            componentKey: 0,
            tableKey: 0,
            stopplossKey: 0,
            bestellTranche: "",
            currentFirma: {},
            dialog: {
                bestellungAusfuehren: 0
            },
            orderdata: {
                orderdaten: {
                    orderedbyName: "",
                    cost: 0,
                    volume: 0,
                    pricing: {
                        price: 0
                    }
                }
            },
            showLieferstellen: false,
            chartOptions: {
                elements: {
                    point: {
                        radius: 0
                    },
                    line: {
                        borderColor: "rgba(0, 0, 0, 0.5)",
                        borderWidth: 2
                    }
                },
                locale: "de",
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    x: {
                        type: "time",
                        time: {
                            unit: "day",
                            displayFormats: {
                                day: "dd.MM.yyyy"
                            },
                            tooltipFormat: "dd.MM.yyyy"
                        },
                        ticks: {
                            maxTicksLimit: 4
                        }
                    },
                    y: {
                        title: {
                            display: true,
                            text: "€ / MWh"
                        }
                    }
                },
                plugins: {
                    legend: {
                        display: false,
                    },
                    annotation: {
                        annotations: []
                    }
                }
            },
            chartData: {
                labels: [],
                datasets: []
            },
            detailChartOptions: {
                locale: "de",
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    x: {
                        type: "time",
                        time: {
                            unit: "day",
                            displayFormats: {
                                day: "dd.MM.yyyy"
                            },
                            tooltipFormat: "dd.MM.yyyy HH:mm"
                        },
                        ticks: {
                            maxTicksLimit: 4
                        }
                    },
                    y: {
                        title: {
                            display: true,
                            text: "€ / MWh"
                        }
                    }
                },
                plugins: {
                    legend: {
                        display: false,
                    },
                    annotation: {
                        annotations: []
                    }
                }
            },
            detailChartData: {
                labels: [],
                datasets: []
            }
        }
    },
    methods: {
        germanNumberStringToNumber(string) {
            let output
            if (typeof (string) == "string") {
                // 4 Möglichkeiten
                // a) Es ist nur ein . im String => ist Number: parseFloat ausreichend
                // b) Es ist nur ein , im String => deutsche Zahl: replace(",", ".") + parseFloat
                // c) Es ist Beides vorhanden. "." erscheint vor "," => deutsche Zahl: replace(".", "") + replace(",", ".") + parseFloat
                // d) Es ist Beides vorhanden. "," erscheint vor "." => amerikanische Zahl: parseFloat ausreichend
                const posPunkt = string.indexOf(".")
                const posKomma = string.indexOf(",")
                if (posPunkt > -1 && posKomma == -1) {
                    // Möglichkeit a)
                } else if (posKomma > -1 && posPunkt == -1) {
                    // Möglichkeit b)
                    string = string.replace(",", ".")
                } else if (posPunkt > -1 && posKomma > -1) {
                    if (posPunkt < posKomma) {
                        // Möglichkeit c
                        string = string.replaceAll(".", "")
                        string = string.replace(",", ".")
                    } else {
                        // Möglichkeit d
                        string = string.replaceAll(",", "")
                    }
                }
                output = parseFloat(string)
            }
            return output;
        },
        setOrderdata() {
            const userdata = loginNew.getProfile()
            const orderdata = {
                tranchenzeitraum: this.aktuellerTranchenzeitraum,
                tranchennummer: this.bestellTranche,
                orderdaten: {
                    ordertype: "Individual",
                    cost: parseFloat(
                        this.aktuelleTranche["cost"].toFixed(3)
                    ),
                    volume: parseInt(
                        this.aktuelleTranche.TrancheMengeinkWh
                    ),
                    fixed: 0,
                    orderdatetime: "",
                    orderedby: userdata.sub,
                    orderedbyName: `${userdata.name} ${userdata.family_name}`,
                    pricing: {
                        baseprice: parseFloat(this.aktuelleTranche.Preise.base.price / 10),
                        peakprice: parseFloat(this.aktuelleTranche.Preise.peak.price / 10),
                        structprice: parseFloat(parseFloat(this.aktuelleTranche["Strukturkonstante"].toFixed(3)) / 10),
                        price:
                            this.aktuelleTranche["Endpreis"]["price"],
                        pricedate_base:
                            this.aktuelleTranche.Preise.base.product_ts,
                        pricedate_peak:
                            this.aktuelleTranche.Preise.peak.product_ts,
                        price_products: this.aktuelleTranche.Produkte
                    },
                    faktorBase: this.aktuelleTranche.FaktorBase,
                    faktorPeak: this.aktuelleTranche.FaktorPeak
                }
            }
            this.orderdata = orderdata
        },
        setOrder(tranchennummer) {
            this.bestellTranche = tranchennummer
            this.setOrderdata()
            this.dialog.bestellungAusfuehren++
        },
        async getPrice(product, debug = false) {
            var data = new FormData()
            data.append("apikey", process.env.VUE_APP_FIREBASE_APIKEY)
            data.append("product", product)
            if (debug) {
                data.append("debug", "true")
            }

            var config = {
                method: "post",
                url: process.env.VUE_APP_FIREBASE_HOSTING + "/queryPrice",
                data: data
            }

            let response = await axios(config)
            console.log(response.data)
            return response.data
        },
        getDateFromTS(ts) {
            if (typeof (ts) == "object") {
                ts = ts.seconds * 1000
            }
            const timestamp = parseInt(ts)
            const pricedateobj = new Date(timestamp)
            return pricedateobj.toLocaleString("de-DE")
        },
        setCurrentFirma(firma) {
            this.$store.dispatch("switchFirma", firma)
        },
        findRanges(arr) {
            if (!Array.isArray(arr) || arr.length === 0) {
                return arr;
            }
            arr.sort((a, b) => a - b);

            let ranges = [];
            let start = arr[0];
            let end = arr[0];
            for (let i = 1; i < arr.length; i++) {
                if (arr[i] === end + 1) {
                    end = arr[i];
                } else {
                    ranges.push(start === end ? `${start}` : `${start}-${end}`);
                    start = end = arr[i];
                }
            }
            ranges.push(start === end ? `${start}` : `${start}-${end}`);
            return ranges.join(",");
        },
        getChartdata(vertrag) {
           

            this.isLoading = true
            const workdata = this.pricehistory
            for (var i = 0; i <= workdata.length - 1; i++) {
                const tmp = workdata[i].timestamp;
                if (tmp < this.chartOptions.scales.x.min) this.chartOptions.scales.x.min = tmp;
                if (tmp > this.chartOptions.scales.x.max) this.chartOptions.scales.x.max = tmp;
            }
            this.chartData = {
                labels: workdata.map(entry => new Date(entry.timestamp * 1000)),
                datasets: [
                    {
                        label: vertrag,
                        backgroundColor: "#f87979",
                        data: workdata.map(entry => parseFloat(entry.lastValue))
                    }
                ]
            }
            const detailWorkdata = workdata.slice(-100);
            this.detailChartData = {
                labels: detailWorkdata.map(entry => new Date(entry.timestamp * 1000)),
                datasets: [
                    {
                        label: vertrag,
                        backgroundColor: "#f87979",
                        data: detailWorkdata.map(entry => parseFloat(entry.lastValue))
                    }
                ]
            }

            // StoppLoss-Limits einzeichnen
            const colors = [
                "rgba(102, 122, 122, 0.5)",
                "rgba(102, 102, 202, 0.5)",
                "rgba(202, 102, 102, 0.5)",
                "rgba(202, 202, 102, 0.5)",
                "rgba(202, 102, 202, 0.5)",
                "rgba(2, 122, 122, 0.5)",
                "rgba(2, 102, 202, 0.5)",
                "rgba(202, 102, 2, 0.5)",
                "rgba(2, 202, 102, 0.5)",
                "rgba(202, 2, 2, 0.5)",
                "rgba(2, 2, 122, 0.5)",
                "rgba(2, 2, 202, 0.5)",
            ]
            let colors_backwards = [
                "rgba(202, 202, 2, 0.5)",
                "rgba(182, 182, 222, 0.5)",
                "rgba(182, 32, 142, 0.5)",
                "rgba(2, 122, 122, 0.5)",
                "rgba(2, 102, 202, 0.5)",
                "rgba(202, 102, 2, 0.5)",
                "rgba(2, 202, 102, 0.5)",
                "rgba(202, 2, 2, 0.5)",
                "rgba(2, 2, 122, 0.5)",
                "rgba(2, 2, 202, 0.5)",
            ];
            let count = 0
            let datasets = [];
            for (const sl of this.aktuellerStoppLoss) {
                let dataset = {
                    type: "line",
                    borderColor: colors_backwards[count],
                    borderDash: [6, 6],
                    borderDashOffset: 0,
                    borderWidth: 3,
                    label: {
                        display: true,
                        backgroundColor: colors_backwards[count],
                        borderWidth: 1,
                        borderColor: colors_backwards[count],
                        color: "black",
                        content: `${sl.name}: ${sl.limit}`,
                        position: "start",
                    },
                    scaleID: "y",
                    value: sl.limit,
                }
                datasets.push(dataset)
                count++
            }
            this.detailChartOptions.plugins.annotation.annotations = datasets

            // geschlossene Tranchen einzeichnen
            // Hierzu Tranchen auslesen, Stundengenau gruppieren und 
            // die Stundengenauen Datetime-Objecte in Tiemstamp zurückkonvertieren
            let output = {}
            for (const sl of this.geschlosseneTranchenNamen) {
                const currentTranche = this.geschlosseneTranchen[sl]
                const currentTrancheOrdertimeStamp = currentTranche && currentTranche.orderdatetime && currentTranche.orderdatetime.seconds * 1000
                const currentTrancheOrdertimeFormated = currentTrancheOrdertimeStamp && format(currentTrancheOrdertimeStamp, "yyyy-MM-dd HH:00")
                const currentTrancheOrdertimeParsed = currentTrancheOrdertimeFormated && parse(currentTrancheOrdertimeFormated, "yyyy-MM-dd HH:00", new Date())
                const currentTrancheOrdertimeGroup = currentTrancheOrdertimeParsed && format(currentTrancheOrdertimeParsed, "t")
                const currentTrancheNumber = sl.match(/\d+$/)[0];
                if (!Object.prototype.hasOwnProperty.call(output, currentTrancheOrdertimeGroup)) {
                    output[currentTrancheOrdertimeGroup] = [];
                }
                output[currentTrancheOrdertimeGroup].push(parseInt(currentTrancheNumber))
            }

            let orderDatasets = []
            count = 0
            for (const time in output) {
                const tranchennummern = output[time]
                const ranges = this.findRanges(tranchennummern)
                orderDatasets.push({
                    type: "line",
                    borderColor: colors[count],
                    borderWidth: 2,
                    label: {
                        display: true,
                        color: "black",
                        backgroundColor: colors[count],
                        content: `Tranche ${ranges}`,
                        position: "start"
                    },
                    scaleID: "x",
                    value: time * 1000
                })
                count++
            }
            this.chartOptions.plugins.annotation.annotations = orderDatasets
            this.isLoading = false
        }
    },
    computed: {
        ...mapState("contract", ["aktuellerVertragsname","aktuelleVertragsnamen","aktuellerVertrag","aktuelleTranche","aktuellerStoppLoss","aktuelleTranchenzeitraeume","aktuellerTranchenzeitraum",
        "aktuelleTranche","geschlosseneTranchenNamen","geschlosseneTranchen","naechsterOffenerTranchenName","TrachenSummen", "abgeschlossenerVertragsart"
        ]),
        ...mapState("priceHistory", ["pricehistory"]),
        localAktuellerVertragsname: {
            get() {
                return this.aktuellerVertragsname;
            },
            set(value) {
                this.$store.state.contract.aktuellerVertragsname = value
            }
        },
        isFirmenArray() {
            if (!this.$store.state.auth.firmen) { return }
            return this.$store.state.auth.firmen.length > 1 ? true : false
        },
        getCurrentFirma() {
            //return this.$store.state.auth.currentFirma ? this.$store.state.auth.currentFirma : {}
            return this.$store.state.auth.currentFirma
        },
        getFirmenList() {
            return this.$store.state.auth.firmen
        },
        sortedTranchen() {
            const tranchenlist = this.aktuelleTranche.Tranchen
            tranchenlist && tranchenlist.sort(function (a, b) {
                const aNumber = a.match(/\d+$/)[0];
                const bNumber = b.match(/\d+$/)[0];
                return aNumber - bNumber;
            })
            return tranchenlist
        },
        computedChartdata() {
           const vertrag = this.aktuellerVertrag.vertragsart
        //    let isLoading = true
           const workdata = this.pricehistory
           const chartOptions = {...this.chartOptions}
           for (var i = 0; i <= workdata.length - 1; i++) {
               const tmp = workdata[i].timestamp;
               if (tmp < this.chartOptions.scales.x.min) chartOptions.scales.x.min = tmp;
               if (tmp > this.chartOptions.scales.x.max) chartOptions.scales.x.max = tmp;
           }
           const chartData = {
               labels: workdata.map(entry => new Date(entry.timestamp * 1000)),
               datasets: [
                   {
                       label: vertrag,
                       backgroundColor: "#f87979",
                       data: workdata.map(entry => parseFloat(entry.lastValue))
                   }
               ]
           }
           const detailWorkdata = workdata.slice(-100);
           const detailChartData = {
               labels: detailWorkdata.map(entry => new Date(entry.timestamp * 1000)),
               datasets: [
                   {
                       label: vertrag,
                       backgroundColor: "#f87979",
                       data: detailWorkdata.map(entry => parseFloat(entry.lastValue))
                   }
               ]
           }

           // StoppLoss-Limits einzeichnen
            const colors = [
               "rgba(102, 122, 122, 0.5)",
               "rgba(102, 102, 202, 0.5)",
               "rgba(202, 102, 102, 0.5)",
               "rgba(202, 202, 102, 0.5)",
               "rgba(202, 102, 202, 0.5)",
               "rgba(2, 122, 122, 0.5)",
               "rgba(2, 102, 202, 0.5)",
               "rgba(202, 102, 2, 0.5)",
               "rgba(2, 202, 102, 0.5)",
               "rgba(202, 2, 2, 0.5)",
               "rgba(2, 2, 122, 0.5)",
               "rgba(2, 2, 202, 0.5)",
            ]
            let colors_backwards = [
               "rgba(202, 202, 2, 0.5)",
               "rgba(182, 182, 222, 0.5)",
               "rgba(182, 32, 142, 0.5)",
               "rgba(2, 122, 122, 0.5)",
               "rgba(2, 102, 202, 0.5)",
               "rgba(202, 102, 2, 0.5)",
               "rgba(2, 202, 102, 0.5)",
               "rgba(202, 2, 2, 0.5)",
               "rgba(2, 2, 122, 0.5)",
               "rgba(2, 2, 202, 0.5)",
            ];
           let count = 0
           let datasets = [];
           for (const sl of this.aktuellerStoppLoss) {
               if (sl.status === "geschlossen") {
                continue
               }
               let dataset = {
                   type: "line",
                   borderColor: colors_backwards[count],
                   borderDash: [6, 6],
                   borderDashOffset: 0,
                   borderWidth: 3,
                   label: {
                       display: true,
                       backgroundColor: colors_backwards[count],
                       borderWidth: 1,
                       borderColor: colors_backwards[count],
                       color: "black",
                       content: `${sl.name}: ${sl.limit}`,
                       position: "start",
                   },
                   scaleID: "y",
                   value: sl.limit,
               }
               datasets.push(dataset)
               count++
           }
           let detailChartOptions = {...this.detailChartOptions}
           detailChartOptions.plugins.annotation.annotations = datasets

           // geschlossene Tranchen einzeichnen
           // Hierzu Tranchen auslesen, Stundengenau gruppieren und 
           // die Stundengenauen Datetime-Objecte in Tiemstamp zurückkonvertieren
           let output = {}
           for (const sl of this.geschlosseneTranchenNamen) {
               const currentTranche = this.geschlosseneTranchen[sl]
               const currentTrancheOrdertimeStamp = currentTranche && currentTranche.orderdatetime && currentTranche.orderdatetime.seconds * 1000
               const currentTrancheOrdertimeFormated = currentTrancheOrdertimeStamp && format(currentTrancheOrdertimeStamp, "yyyy-MM-dd HH:00")
               const currentTrancheOrdertimeParsed = currentTrancheOrdertimeFormated && parse(currentTrancheOrdertimeFormated, "yyyy-MM-dd HH:00", new Date())
               const currentTrancheOrdertimeGroup = currentTrancheOrdertimeParsed && format(currentTrancheOrdertimeParsed, "t")
               const currentTrancheNumber = sl.match(/\d+$/)[0];
               if (!Object.prototype.hasOwnProperty.call(output, currentTrancheOrdertimeGroup)) {
                output[currentTrancheOrdertimeGroup] = [];
               }
               output[currentTrancheOrdertimeGroup].push(parseInt(currentTrancheNumber))
           }

            let orderDatasets = []
            count = 0
            for (const time in output) {
               const tranchennummern = output[time]
               const ranges = this.findRanges(tranchennummern)
               orderDatasets.push({
                   type: "line",
                   borderColor: colors[count],
                   borderWidth: 2,
                   label: {
                       display: true,
                       color: "black",
                       backgroundColor: colors[count],
                       content: `Tranche ${ranges}`,
                       position: "start"
                   },
                   scaleID: "x",
                   value: time * 1000
               })
               count++
            }
           chartOptions.plugins.annotation.annotations = orderDatasets
        //    isLoading = false

            return ({
                chartData,
                chartOptions,
                detailChartData,
                detailChartOptions
            })
        }
    },
    watch: {
        computedChartdata: {
            handler() {
                setTimeout(() => {
                    this.isLoading = false // Turn off loading when computation is done
                }, 3000)
            },
            immediate: true,// Run immediately on component mount
            deep: true
        },
        async getCurrentFirma(newval) {
            this.$store.dispatch("contract/holeVertragsdaten", { firma_id: newval.id })
        },
        "$store.state.contract.aktuelleTranche.Preise.base.product_ts": {
            handler: function (newval) {
                if (!newval) {
                    return
                }
                const timestamp = parseInt(newval)
                const pricedateobj = new Date(timestamp)
                this.priceDate = pricedateobj.toLocaleString("de-DE")
                this.componentKey++
            },
            immediate: true
        },
        "$store.state.contract.geschlosseneTranchenNamen": {
            handler: function () {
                // this.getChartdata(this.aktuellerVertrag.vertragsart)
            },
            immediate: true
        },
        "$store.state.contract.aktuellerVertragsname": {
            handler: function (newval) {
                this.isLoading = true; 
                if (!newval) {
                    this.detailChartData = {
                        labels: [],
                        datasets: []
                    }
                    this.chartData = {
                        labels: [],
                        datasets: []
                    }
                    return
                }
                this.$store
                    .dispatch("contract/setAktuellerVertrag", { vertrag: newval })
                    .then(() => {
                        this.$store.dispatch("contract/calculatePrice").then(() => {
                            this.componentKey++
                        })
                    })
                this.$store.dispatch("priceHistory/getPriceHistory", { vertrag: this.aktuellerVertrag.vertragsart })
                    .then(() => {
                        // this.getChartdata(this.aktuellerVertrag.vertragsart)
                    })
            },
            immediate: true
        },

        "$store.state.contract.aktuellerTranchenzeitraum": {
            handler: function (newval) {
                if (!newval) {
                    return
                }
                this.readonly = true
                if (this.aktuellerVertrag.vertragsart === "Tranche individual") {
                    this.readonly = false
                } else {
                    this.$store.dispatch("contract/getAktuelleStoppLossDaten", {
                        vertrag: this.aktuellerVertrag.vertragsart
                    }).then(() => {
                        this.stopplossKey++
                    })
                }
                this.$store
                    .dispatch("contract/setAktuelleTranche", {
                        tranchenzeitraum: newval
                    })
                    .then(() => {
                        this.$store.dispatch("contract/calculatePrice").then(() => {
                            this.$store
                                .dispatch("contract/getOrderedTranches", {
                                    tranchenzeitraum: newval
                                })
                                .then(() => {
                                    this.componentKey++
                                })
                        })
                    })
            },
            immediate: true
        },
        "$store.state.contract.reloadOrderview": {
            handler: function () {
                this.$store.state.contract.reloadOrderview = false
            }
        },
        getFirmenList() {
            if (!this.$store.state.auth.currentFirma) {
                this.setCurrentFirma(this.$store.state.auth.firmen[0])
            }
            this.currentFirma = this.$store.state.auth.currentFirma
        }

    },
    async mounted() {
        // SignIn Check
        this.isLoading = true; 
        if (!this.$store.state.auth.isSignedIn) {
            this.$router.push("/login")
            return
        }
        // SignIn Check
        if (!this.$store.state.auth.authClaims.permissions.aufrufBewirtschaftung) {
            this.$router.push("/dashboard")
            return
        }
        this.$store
            .dispatch("contract/holeStammdaten", { firmen: this.$store.state.auth.firmen })
            .then(() => {
                const cur = this.getCurrentFirma
                this.$store.dispatch("contract/holeVertragsdaten", { firma_id: cur.id })
            })
        this.$store.dispatch("priceHistory/getPriceHistory", { vertrag: this.aktuellerVertrag.vertragsart })
            .then(() => {
                // this.getChartdata(this.aktuellerVertrag.vertragsart)
            })
    }
}
</script>

<style>
.v-data-table table tfoot th {
    position: sticky;
    bottom: 0;

}
</style>